import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, combineLatest } from 'rxjs';
import { environment } from '../../../environments/environment';

import { AuditLogMessage } from '@cdw-ae/common-data';
import { AuditLogStrings } from 'amplified-backend-libraries/client';
import { GenericService } from '../generic.service';

export interface Audit {
  entry?: number;
  created_on?: Date;
  type: string;
  action: string;
  data: string;
  customer_id?: any;
  user_id?: number;
  customer?: string;
}

export interface ListResponse {
  /**
   * Paged list of logs
   */
  data: Audit[];
  /**
   * Total number of logs
   */
  count: number;
}


@Injectable()
export class AuditsService extends GenericService {

  protected type;
  protected serviceUrls = environment.urls.userServices;

  constructor(protected http: HttpClient) {

    super(http, {type: 'audits'});

  }

  createAuditLog(auditEntry: any): Observable<[any,any]> {
    const body = auditEntry;
    body.type = AuditLogStrings.lsc.types[auditEntry.typeId].type;
    body.action = AuditLogStrings.lsc?.types[auditEntry.typeId]?.actions[auditEntry.actionId]?.action;

    const auditLogMessage: AuditLogMessage = {
      dt: new Date(),
      category: AuditLogStrings.lsc.types[auditEntry.typeId].type,
      typeId: auditEntry.typeId,
      action: AuditLogStrings.lsc?.types[auditEntry.typeId]?.actions[auditEntry.actionId]?.action,
      actionId: auditEntry.actionId,
      actionData: JSON.stringify(auditEntry.data, null, 2),
      area: (auditEntry.productCode || 'lsc'),
      userId: auditEntry.user_id,
      customerId: auditEntry.customer_id,
    };

    const oldAuditLogUrl = this.serviceUrls.audits.route;
    const auditLogUrl = this.serviceUrls.audits.create;

    return combineLatest([
      this.http.post<any>(oldAuditLogUrl, body, this.httpOptions),
      this.http.post<any>(auditLogUrl, auditLogMessage, this.httpOptions)
    ]);

  }

}
